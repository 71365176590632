    $(function() { 
var mql = window.matchMedia('screen and (min-width: 768px)');
        
    function sliderStart() {
    var slide = document.getElementById('index-mv');     
    var slideItem = slide.querySelectorAll('.index-mv-item'); 
    var totalNum = slideItem.length - 1;
    var FadeTime = 1000;                                    
    var IntarvalTime = 6000;                              
    var actNum = 0;
    var navNum = 0;     
    var nowSlide;                                            
    var NextSlide;     
        
    slideItem[0].classList.add('show_', 'zoom_');
    setInterval(function(){
        if (actNum < totalNum) {
            var nowSlide = slideItem[actNum];
            var NextSlide = slideItem[++actNum];           
            nowSlide.classList.remove('show_');
            NextSlide.classList.add('show_', 'zoom_');         
            setTimeout(function(){
                nowSlide.classList.remove('zoom_');                
            }, FadeTime);

        } else {
            var nowSlide = slideItem[actNum];
            var NextSlide = slideItem[actNum = 0];
            nowSlide.classList.remove('show_');
            NextSlide.classList.add('show_', 'zoom_');            
            setTimeout(function(){
               nowSlide.classList.remove('zoom_');
            }, FadeTime);
        };
    }, IntarvalTime);
}

setTimeout(sliderStart,500);        
//sliderStart(); 
});
